$(document).ready(function () {


  /**/
  /* Mobile navigation */
  /**/
  $('#navbarMobile').on('show.bs.collapse hide.bs.collapse', function (e) {
    toggleCollapse(e);
  })

  function toggleCollapse(e) {
    if ($(e.target).hasClass('children')) {
      // Collapse arrow for oplossingen in mobile navigation
      $('#caret').toggleClass('fa-caret-down fa-caret-up');
    }
    else {
      $('#navbarMobile').toggleClass('full-height');
      $('.navbar').toggleClass('navbar-light navbar-dark');
      $('.navbar-brand').find('img').toggle();
      // $('.LandbotLivechat-container').toggle();
      $('.LandbotLivechat-container').toggleClass('z-minus');
    }
  }


  /**/
  /* Contact Modal in navbar and product pages */
  /**/

  // $('.contact-modal').click(function () {
  //   $('#contact-modal').modal('show', $(this));
  // })

  $('.modal').on('show.bs.modal', function (e) {
      $(e.delegateTarget).find('.modal-dialog').css("z-index", 9999);
  });

  /**/
  /* Endless scrolling for cases on /ons-werk and /blog */
  /**/

  if ($('#cardsParent').length > 0) {
    var page = 1;
    var collectionType = 'blog';
    var jumboHeight = 0;
    var footerHeight = 0;
    var currentScrollHeight = 0;

    if (window.location.pathname === '/ons-werk') {
      collectionType = 'case';
    }

    if (window.location.pathname === '/modules') {
      collectionType = 'modules';
    }

    if ($('#jumboHeight').length > 0) {
      jumboHeight = $('#jumboHeight').outerHeight();
    }

    if ($('.footer').length > 0) {
      footerHeight = $('.footer').outerHeight()
    }

    $(window).on("scroll", function () {

      var scrollHeight = $(document).height();
      var scrollPos = $(window).height() + $(window).scrollTop();
      var atBottom = scrollHeight - (jumboHeight + footerHeight) < scrollPos;

      if (atBottom && currentScrollHeight < scrollHeight) {
        page++;
        fetchEvents(collectionType, page);

        currentScrollHeight = scrollHeight;
      }

    });
  }


  function fetchEvents(collectionType, page) {
    var apiPath = '/api/' + collectionType + '?page=' + page;
    var searchPrefix = '&category=';
    var filterArray = getParams();

    if (filterArray.length != 0) {
      apiPath += searchPrefix + filterArray.join('|');
    }

    axios
      .get(apiPath)
      .then(function (response) { cardsParent.insertAdjacentHTML('beforeend', response.data) });
  }


  /**/
  /* Filter for cases on /ons-werk and /blog */
  /**/

  $('.filter-btn').addClass(function () {
    if (getParams().includes(this.value)) {
      return 'active';
    }
  });

  $('.filter-btn').click(function () {
    window.location.href = filterTaxonomy($(this));
  });

  function filterTaxonomy(btn) {
    var scrollTo = '#' + btn.data('filter-scroll-to');
    var searchPrefix = '?' + btn.data('filter-taxonomy') + '=';
    var term = btn.val();
    var filterArray = getParams();

    if (filterArray.includes(term)) {
      // Filter has category => delete category
      filterArray = filterArray.filter(function (item) { return item !== term });
    }
    else {
      // Filter doesn't have category => add category
      filterArray.push(term);
    }

    if (filterArray.length != 0) {
      params = searchPrefix + filterArray.join('|');
    }
    else {
      params = '';
    }

    return window.location.protocol + "//" + window.location.host + window.location.pathname + params + scrollTo;
  }

  function getParams() {
    var params = [];
    if (window.location.search != 0) {
      params = window.location.search.split('=')[1].split('|');
    }

    return params;
  }


  /**/
  /* Copy url to clipboard on case or article page. */
  /**/

  $('#copy-url').on('click', function () {
    copyTextToClipboard(window.location.href);
  });

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      $('#copy-url').addClass('copy-confirm');

      setTimeout(function () {
        $('#copy-url').removeClass('copy-confirm');
      }, 1000);
    } catch (err) { }

    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function () {
      $('#copy-url').addClass('copy-confirm');
      setTimeout(function () {
        $('#copy-url').removeClass('copy-confirm');
      }, 1000);
    });
  }


  /**/
  /* From validation */
  /**/

  /* Newsletter-form in footer */
  $('#newsletter-form').submit(function (e) {
    e.preventDefault();

    var email = $('#newsletter-email').val();
    var honeypot = $('#newsletter-honopoto').val();

    if (honeypot.length < 1) {
      if (!validateMail(email)) {
        $('#newsletter-error-email').addClass("show");
        return;
      }
      $('#newsletter-error-email').removeClass("show");

      if (!$("#newsletter-privacy")[0].checked) {
        $('#newsletter-error-privacy').addClass("show");
        return;
      }
      $('#newsletter-error-privacy').removeClass("show");

      __ss_noform.push(['submit', null, 'd3506f80-daa7-4109-ab64-19aaa15dc34d']);

      var form = e.target;
      var form_data = new FormData(form);
      var request = new XMLHttpRequest();

      request.onreadystatechange = function () {
        $('#newsletter-form').hide();
        $('#newsletter-success').show();
      }

      request.open(form.method, form.action);
      request.send(form_data);
    }
  });

  /* Contact-form on contact page */

  $('#contact-form').submit(function (e) {
    e.preventDefault();

    var honeypot = $('#contact-honopoto').val();
    var firstName = $('#contact-firstname').val();
    var lastName = $('#contact-lastname').val();
    var email = $('#contact-email').val();
    var message = $('#contact-message').val();

    if (honeypot.length < 1) {

      if (firstName.length < 2) {
        $('#contact-error-firstname').addClass("show");
        return;
      }
      $('#contact-error-firstname').removeClass("show");

      if (lastName.length < 2) {
        $('#contact-error-lastname').addClass("show");
        return;
      }
      $('#contact-error-lastname').removeClass("show");

      if (!validateMail(email)) {
        $('#contact-error-email').addClass("show");
        return;
      }
      $('#contact-error-email').removeClass("show");

      if (message.length < 10) {
        $('#contact-error-message').addClass("show");
        return;
      }
      $('#contact-error-message').removeClass("show");

      if (!$("#contact-privacy")[0].checked) {
        $('#contact-error-privacy').addClass("show");
        return;
      }
      $('#contact-error-privacy').removeClass("show");

      __ss_noform.push(['submit', null, '3777304e-9a2b-4c6d-bbc5-b3aba0212ba7']);

      var form = e.target;
      var form_data = new FormData(form);
      var request = new XMLHttpRequest();

      request.onreadystatechange = function () {
        $('#contact-submit').hide();
        $('#contact-success').show();
      }

      request.open(form.method, form.action);
      request.send(form_data);
    }
  });

  function validateMail(email) {
    var regEx = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (email.length < 2) {
      return false;
    }

    return regEx.test(email);
  }


  /**/
  /* Testimonial carousel */
  /**/

  var testimonials = $('#testimonialCarousel').carousel();
  var images = $('#imageCarousel').carousel();

  testimonials.on('slide.bs.carousel', function (event) {
    var to = $(event.relatedTarget).index();
    images.carousel(to);
  });

});
